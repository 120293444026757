import { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import DateBadge from '../intro-table/DateBadge';
import { STATUS } from 'common';

interface Props {
  feedback: any;
  contact: any;
}

export default function FeedbackCard({ feedback, contact }: Props) {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="border-gray-200 rounded-lg border p-5">
      <div className="flex justify-between items-center">
        <p className="text-md text-emerald-600">
          {`${contact.firstName} ${contact.lastName}'s Feedback`}{' '}
          <span className="text-black">
            {' - ' + new Date(feedback.createdAt).toDateString()}
          </span>
        </p>
        <button onClick={() => setOpen(!isOpen)}>
          <ChevronDownIcon
            className={`h-5 w-5 text-gray-600 transition-transform ${
              isOpen ? 'rotate-180' : ''
            }`}
          />
        </button>
      </div>

      {isOpen && (
        <>
          {!feedback.fulfilled ? (
            <p className="text-sm text-red-500 mt-2">
              The user has not filled out the feedback.
            </p>
          ) : feedback.intentionToMeet === false ? (
            <p className="text-sm text-red-500 mt-2">
              User does not intend to meet.
            </p>
          ) : (
            <>
              <div className="flex items-center mt-2">
                {feedback.haveMet ? (
                  <span className="px-2 py-1 text-sm font-semibold text-white bg-emerald-500 rounded-lg">
                    Has Met
                  </span>
                ) : (
                  <span className="px-2 py-1 text-sm font-semibold text-white bg-red-500 rounded-lg">
                    Has NOT Met
                  </span>
                )}
              </div>

              <div className="flex items-center mt-2">
                {feedback.isGoodFit ? (
                  <span className="px-2 py-1 text-sm font-semibold text-white bg-emerald-500 rounded-lg">
                    Good Fit
                  </span>
                ) : (
                  <span className="px-2 py-1 text-sm font-semibold text-white bg-red-500 rounded-lg">
                    Not Good Fit
                  </span>
                )}
              </div>

              {feedback.introExperience && (
                <div className="flex items-center mt-2">
                  <span className="px-2 py-1 text-sm font-semibold text-white bg-gray-500 rounded-lg">
                    {feedback.introExperience}
                  </span>
                </div>
              )}

              <p className="text-sm text-gray-600 mt-2">Notes:</p>
              <p className="text-sm">
                {feedback.notes || 'No notes available'}
              </p>
            </>
          )}
        </>
      )}
    </div>
  );
}
