import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import Icon from "../Icon";

function AccordionAccount({
  children,
  title,
  first,
  icon,
  isOpen = false, // Default value
  disabled,
  onClick,
}) {
  return (
    <div>
      <button
        type="button"
        className={`w-full px-4 inline-flex justify-between items-center border border-gray-300 h-14 cursor-pointer transition-all
          duration-200 ${first ? 'rounded-t-lg' : ''} ${
          isOpen ? 'bg-gray-200' : 'bg-white'
        }`}
        onClick={onClick}
        disabled={disabled} // Disable button if `disabled` is true
      >
        <div className="inline-flex space-x-4">
          <Icon
            icon={icon}
            iconClass="w-5 h-5 mr-2"
            className={`flex-row ${isOpen ? 'text-gray-500' : 'text-black'}`}
            text={title}
          />
          {disabled && (
            <div className="bg-yellow-200 rounded text-yellow-800 px-4 text-sm py-1">
              Coming Soon!
            </div>
          )}
        </div>
        {isOpen ? (
          <Icon
            icon={<ChevronUpIcon />}
            iconClass="w-5 h-5"
            className="text-gray-500"
          />
        ) : (
          <Icon
            icon={<ChevronDownIcon />}
            iconClass="w-5 h-5"
            className="text-black"
          />
        )}
      </button>

      <div
        className={`text-gray-500 bg-white border border-t-0 border-gray-300 overflow-hidden transition-all duration-300 ${
          isOpen ? 'h-fit' : 'max-h-0'
        }`}
      >
        <div className="px-4 py-2">{children}</div>
      </div>
    </div>
  );
}

export default AccordionAccount;
