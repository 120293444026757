import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Collaboration from '../../icon/Collaboration.png'

/**
 *
 */
interface Props {
  onClick: () => void;
}
export default function FinishedTutorialModal({ onClick }: Props) {
  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClick}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="displayFinishedTutorialModalease-in duraion-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative overflow-hidden text-left bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                <>
                  <div className="">
                    <div className="flex items-center w-fit mx-auto pt-8">
                      <img src={Collaboration} alt="" />
                    </div>
                    <div className="space-y-2 text-center">
                      <h2 className='text-2xl font-bold'>You did it! You've finished the tutorial.</h2>
                      <p>Now you're ready to connect and grow with Looop. Enjoy the journey!</p>
                    </div>

                    <div className="w-full  space-y-2 mb-4 px-4 pt-4">
                      <div className='border-gray-200 border-t pt-4'/>
                      <button
                        onClick={onClick}
                        className="w-full bg-emerald-700 text-white h-14 mt-4 rounded-lg box-border hover:bg-emerald-500 hover:text-white"
                      >
                        Let’s go!
                      </button>
                    </div>
                  </div>
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
