import React, { Fragment, useState, useEffect, ReactNode } from 'react';
import Handlebars from 'handlebars';
import { Dialog, Transition } from '@headlessui/react';
import Icon from '../Icon';
import { userInfo } from 'os';

/**
 * Modal Displayed when creating a contact
 */
interface Props {
  onClose: () => void;
  userData: any;
  subject: string;
  body: string;
  selectedContacts?: any[];
}
export default function PreviewModal({
  subject,
  body,
  userData,
  onClose,
  selectedContacts,
}: Props) {
  /**
   * Contact to string for text
   */
  const toString = (contact) => {
    if (!contact) return '';

    const firstName = contact.firstName ?? '';
    const lastName = contact.lastName ?? '';

    return `${firstName} ${lastName}`;
  };

  const handlebarsContext = {
    facilitator: userData,
    contacts: selectedContacts
      ? selectedContacts
      : [
          {
            firstName: 'John',
            lastName: 'Doe',
          },
          {
            firstName: 'Bob',
            lastName: 'Lablaw',
          },
        ],
  };

  /**
   * Using the Rendered Template Message, lets prepop
   * the text.
   */
  Handlebars.registerHelper('toString', (e) => toString(e));

  const renderedSubject = Handlebars.compile(subject)(handlebarsContext);
  const renderedBody = Handlebars.compile(body)(handlebarsContext);

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duraion-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative overflow-hidden text-left bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:w-2/3 sm:max-w-1/3">
                <div className={'pt-10 mx-auto space-y-4 p-12'}>
                  <h1 className="text-emerald-700 text-xl font-bold">
                    Email Preview
                  </h1>
                  <div className="grid sm:grid-cols-2 sm:gap-x-4 gap-y-4 grid-cols-1 text-center py-4">
                    <p className="bg-emerald-600 rounded p-4 w-full sm:w-2/4 mx-auto text-white min-h-[5rem] flex items-center justify-center">
                      {`Contact 1: ${
                        selectedContacts && selectedContacts[0]
                          ? `${selectedContacts[0].firstName} ${selectedContacts[0].lastName}`
                          : 'John Doe'
                      }`}
                    </p>
                    <p className="bg-blue-600  rounded p-4 w-full sm:w-2/4 mx-auto text-white min-h-[5rem] flex items-center justify-center">
                      {`Contact 2: ${
                        selectedContacts && selectedContacts[1]
                          ? `${selectedContacts[1].firstName} ${selectedContacts[1].lastName}`
                          : 'Bob Loblaw'
                      }`}
                    </p>
                  </div>

                  <label className="py-2 text-lg font-bold">Subject</label>
                  <div className="border-2 border-gray-200 rounded p-4 bg-gray-200">
                    <div
                      dangerouslySetInnerHTML={{ __html: renderedSubject }}
                    />
                  </div>
                  <label className="py-2 text-lg font-bold">Body</label>
                  <div className="border-2 border-gray-200 rounded p-4 bg-gray-200 ">
                    <div
                      className="space-y-4"
                      dangerouslySetInnerHTML={{ __html: renderedBody }}
                    />
                  </div>
                </div>
                <button
                  className="float-right bg-emerald-600 rounded w-30 h-10 px-10 mr-4 mb-4 text-white"
                  onClick={onClose}
                >
                  Close
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
