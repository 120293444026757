import React from 'react';
import DesignerDesk from '../../icon/DesignerDesk.png';

interface Props {
  onContinue: () => void;
  onSkip: () => void;
}
export default function WelcomeToDashboard({ onContinue, onSkip } : Props) {

  return (
    <>
      <div className="">
        <div className="flex items-center w-fit mx-auto pt-8">
          <img src={DesignerDesk} alt="" />
        </div>
        <div className="text-center text-2xl font-bold">
          <h2>Welcome to Your Dashboard!</h2>
          <p>Here, you can manage introductions and view all your contacts.</p>
        </div>

        <div className="w-full px-8 space-y-4 pb-4">
          <button
            onClick={onContinue}
            className="w-full bg-emerald-700 text-white h-16 mt-6 rounded-lg box-border"
          >
            Start Tutorial
          </button>
          <button
            onClick={onSkip}
            className="w-full text-emerald-700 bg-white h-16 rounded-lg box-border mb-4 hover:bg-emerald-200"
          >
            Skip Tutorial
          </button>
        </div>
      </div>
    </>
  );
}
