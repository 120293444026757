import { STATUS, WorkflowState } from 'common';
import Reminder from './Reminder';
import Caboose from './Caboose';
import {
  HandThumbDownIcon,
  HandThumbUpIcon,
  PauseIcon,
  PlayIcon,
} from '@heroicons/react/24/solid';
import EmailSent from './EmailSent';
import { groupFeedbackByAttempt } from '../../Utilities';

interface Props {
  introduction: any;
  onStatusToggle: (f) => void;
}

export default function Row({ introduction, onStatusToggle }: Props) {
  // Example usage:
  const feedbackAttempts = groupFeedbackByAttempt(introduction);

  const status = introduction.introStatus;

  let caboose = <></>;

  if (introduction.result) {
    caboose = (
      <Caboose
        reason={introduction.result}
        status={status}
        icon={
          status === STATUS.SUCCESSFUL.id ? (
            <HandThumbUpIcon />
          ) : (
            <HandThumbDownIcon />
          )
        }
        title={
          status === STATUS.SUCCESSFUL.id ? 'Intro Completed' : 'Intro Closed'
        }
        lastUpdatedAt={introduction.updatedAt}
      />
    );
  }
  

  return (
    <span className="flex space-x-4 text-sm">
      <EmailSent createdAt={introduction.createdAt} />
      <div className="w-4 h-1 border-dashed border-t-2 my-auto mx-2 border-gray-500" />
      {feedbackAttempts.length > 0 ? (
        feedbackAttempts.map((group, index) => (
          <>
            <Reminder items={group} status={status} />
            {index !== feedbackAttempts.length - 1 && (
              <div className="w-4 h-1 border-dashed border-t-2 my-auto mx-2 border-gray-500" />
            )}
          </>
        ))
      ) : (
        <Reminder
          items={[
            {
              lastCompletedState: WorkflowState.ORIGINAL,
              createdAt: introduction.createdAt
            },
            {
              lastCompletedState: WorkflowState.ORIGINAL,
              createdAt: introduction.createdAt
            }
          ]}
          status={introduction.status}
        />
      )}
      {caboose}
      <div className="my-auto">
        {introduction.introStatus === STATUS.IN_PROGRESS.id && (
          <button
            id="statusIcon"
            className="w-10 h-10 bg-yellow-500 text-yellow-800 rounded shadow-md"
            onClick={onStatusToggle}
          >
            <PauseIcon className="w-5 h-6 mx-auto" />
          </button>
        )}
        {introduction.introStatus === STATUS.PAUSED.id && (
          <button
            className="w-10 h-10 bg-blue-500 text-blue-800 rounded shadow-md"
            onClick={() => onStatusToggle(introduction)}
          >
            <PlayIcon className="w-5 h-6 mx-auto" />
          </button>
        )}
      </div>
    </span>
  );
}
