import React, { Fragment, useEffect, useState } from 'react';
import OnboardingBusinessDeal from '../../icon/OnboardingBusinessDeal.png';

import Input from '../Input';
import emptyOrBlank from '../../Utilities';

interface Props {
  userData: any;
  onClick: () => void;
}
export default function UpdateUserInformation({ userData, onClick }) {
  const [firstName, setFirstName] = useState(userData.firstName);
  const [lastName, setLastName] = useState(userData.lastName);

  const inputs = {
    firstName: {
      value: firstName,
      titleText: 'First Name',
      placeholderText: 'First',
      id: 'firstName',
      validationFunc: () => emptyOrBlank(firstName),
      required: true,
      type: 'input',
      onChange: (e) => setFirstName(e.target.value),
    },
    lastName: {
      value: lastName,
      titleText: 'Last Name',
      placeholderText: 'Last',
      id: 'lastName',
      validationFunc: () => emptyOrBlank(lastName),
      required: true,
      type: 'input',
      onChange: (e) => setLastName(e.target.value),
    },
  };

  return (
    <>
      <div className="">
        <div className="flex items-center w-fit mx-auto pt-8">
          <img src={OnboardingBusinessDeal} alt="" />
        </div>
        <div className="text-center text-2xl font-bold">
          <h2>Welcome to Looop! </h2>
          <h2>Please share your name with us to get started.</h2>
        </div>

        <div className="grid grid-cols-2 pt-8">
          {Object.keys(inputs).map((key) => {
            const i = inputs[key];
            return (
              <div className="px-8">
                <Input
                  validationFunc={i.validationFunc}
                  titleText={i.titleText}
                  placeholderText={i.placeholderText}
                  inputChangeCallback={(e) => i.onChange(e)}
                  value={i.value}
                  required={i.required}
                  type={i.type}
                />
              </div>
            );
          })}
        </div>
        <div className="w-full px-8">
          <button
            onClick={() => onClick(firstName, lastName)}
            className="w-full bg-emerald-700 text-white h-10 my-6 rounded-lg box-border"
          >
            Get Started
          </button>
        </div>
      </div>
    </>
  );
}
