import React from 'react';
import propTypes from 'prop-types';
import {
  EnvelopeIcon,
  ClockIcon,
  PlayIcon,
  PauseIcon,
} from '@heroicons/react/24/solid';
import moment from 'moment';
import Icon from './Icon';
import Status, { STATUS } from 'common';

/**
 * Recipient
 */
export function Recipient({ recipients }) {
  if (!recipients) {
    return <div />;
  }

  return (
    <div className="-space-y-2">
      {recipients.map((r, index) => (
        <div className="flex" key={`${index + r}`}>
          <div className="flex items-center justify-center w-10 h-10 text-2xl text-white bg-black border-2 border-white rounded-full">
            {r.firstName[0]}
          </div>
          <p className="p-2">{`${r.firstName} ${r.lastName}`}</p>
        </div>
      ))}
    </div>
  );
}

Recipient.propTypes = {
  recipients: propTypes.arrayOf(propTypes.shape({})).isRequired,
};

/**
 * Status for an Introduction
 */
export function IntroductionStatus({ status }) {
  return (
    <div className="block">
      <p
        className={`p-2 font-bold ${status.textDarkColor} ${status.lightColor} bg-blue-200 rounded-lg text-center`}
      >
        {status.text}
      </p>
    </div>
  );
}

IntroductionStatus.propTypes = {
  status: propTypes.object.isRequired,
};

/**
 * Intro Email Card
 */
function IntroEmailCard({ introduction }) {
  return (
    <div className="inline-flex py-1 border border-gray-200 rounded-lg space-x-2">
      <div className="inline-flex">
        <Icon
          className="m-auto"
          iconClass="w-4 h-4 mt-2 m-2"
          icon={<EnvelopeIcon />}
        />
        <div className="m-auto">
          <p className="font-semibold">Intro Email</p>
          {/* <p className="italic"> */}
          {/* {`${introEmail.responses}/2 Recipient Responses`} */}
          {/* </p> */}
        </div>
      </div>
      <div className="my-auto">
        <p className="p-1 px-2 my-auto mr-2 text-xs font-bold text-green-700 bg-green-200 rounded-lg text-bold">
          {
            /* eslint-disable*/ `Sent - ${moment(introduction.createdAt).format(
              'MMMM Do YYYY, h:mm:ss a'
            )}`
          }
        </p>
      </div>
    </div>
  );
}

IntroEmailCard.propTypes = {
  introduction: propTypes.object.isRequired,
};

/**
 * Feedback Email Card used in row
 */
function FeedbackEmailCard({
  responseCount,
  sentDate,
  reminderDate,
  latest,
  status,
  attempt,
}) {
  const getStatusIcon = (status) => {
    if (status.id === Status.IN_PROGRESS.id) {
      return (
        <Icon
          className="my-auto"
          iconClass="bg-blue-700 text-white rounded p-1 w-5 h-5"
          icon={<PlayIcon />}
        />
      );
    }

    if (status.id === Status.PAUSED.id) {
      return (
        <Icon
          className="my-auto"
          iconClass="bg-yellow-600 text-white rounded p-1 w-5 h-5"
          icon={<PauseIcon />}
        />
      );
    }

    return;
  };
  return (
    <div className="inline-flex py-1 border border-gray-200 rounded-lg space-x-2">
      <div className="inline-flex">
        <Icon
          className="m-auto"
          iconClass="w-4 h-4 mt-2 m-2"
          icon={<ClockIcon />}
        />
        <div className="m-auto">
          <p className="font-semibold">Feedback Request Email</p>
          <p className="italic">{`${responseCount}/2 Recipient Responses`}</p>
        </div>
      </div>
      <div className="space-y-1">
        {sentDate.isAfter() && (
          <p className="inline-block h-6 p-1 px-2 text-xs font-bold text-blue-700 bg-blue-200 rounded-lg text-bold">
            {`Scheduled - ${moment(sentDate).format(
              'MMMM Do YYYY, h:mm:ss a'
            )}`}
          </p>
        )}

        {sentDate.isBefore() && (
          <p className="inline-block h-6 p-1 px-2 text-xs font-bold text-green-700 bg-green-200 rounded-lg text-bold">
            {`Sent - ${moment(sentDate).format('MMMM Do YYYY, h:mm:ss a')}`}
          </p>
        )}

        <div />

        {reminderDate && responseCount !== 2 && attempt < 2 && (
          <p
            className={
              'inline-block h-6 p-1 px-2 text-xs font-bold rounded-lg text-bold ' +
              (reminderDate.isAfter()
                ? 'text-blue-700 bg-blue-200'
                : 'text-green-700 bg-green-200')
            }
          >
            {(reminderDate.isBefore()
              ? 'Reminder Scheduled - '
              : 'Reminder Sent ') +
              reminderDate.format('MMMM Do YYYY, h:mm:ss a')}
          </p>
        )}
      </div>
      {latest && getStatusIcon(status)}
    </div>
  );
}

FeedbackEmailCard.defaultProps = {
  reminderDate: null,
};

FeedbackEmailCard.propTypes = {
  responseCount: propTypes.number.isRequired,
  sentDate: propTypes.object.isRequired,
  reminderDate: propTypes.object,
};

/**
 * Timeline used for  an introduction
 */
export function IntroductionTimeline({ introduction, onStatusToggle }) {
  const spacer = (
    <p className="inline-flex m-auto font-extrabold text-gray-400"> --- </p>
  );
  const numCards = Object.entries(introduction.feedback).length;

  const allFeedbacks = !numCards ? (
    <FeedbackEmailCard
      responseCount={0}
      sentDate={moment(introduction.createdAt).add(2, 'weeks')}
    />
  ) : (
    Object.values(introduction.feedback).map((feedbackAttempt, i) => (
      <div className="inline space-x-2" key={`${i + feedbackAttempt}`}>
        <FeedbackEmailCard
          responseCount={introduction.responses}
          sentDate={moment(feedbackAttempt.createdAt)}
          reminderDate={moment(feedbackAttempt.createdAt).add(
            feedbackAttempt.reminderDate,
            'milliseconds'
          )}
          latest={i === numCards - 1}
          status={introduction.status}
          attempt={i}
        />
        {i < numCards - 1 && spacer}
      </div>
    ))
  );

  return (
    <div className="w-full block justify-center">
      <div className="inline-flex space-x-2">
        <IntroEmailCard introduction={introduction} />

        {introduction.introStatus === STATUS.IN_PROGRESS.id && (
          <button className="w-10 h-10 bg-yellow-500 text-yellow-800 rounded"
            onClick={onStatusToggle}
          >
            <PauseIcon className="w-5 h-6 mx-auto" />
          </button>
        )}

        {introduction.introStatus === STATUS.PAUSED.id && (
          <button
            className="w-10 h-10 bg-blue-500 text-blue-800 rounded"
            onClick={() => onStatusToggle(introduction)}
          >
            <PlayIcon className="w-5 h-6 mx-auto" />
          </button>
        )}

        {/* {spacer} */}
        {/* {allFeedbacks} */}
      </div>
    </div>
  );
}
IntroductionTimeline.propTypes = {
  introduction: propTypes.shape([propTypes.shape({})]).isRequired,
};
