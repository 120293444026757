import React, { useState } from 'react';
import { SparklesIcon } from '@heroicons/react/24/solid'; // Make sure to import the icon
import Icon from '../Icon';

export interface Option {
  id: string;
  text: string;
  class: string;
  templatestring: string;
}

export const OPTIONS = [
  {
    id: 'My Name -- Full Name',
    text: 'My Name -- Full Name',
    class: 'bg-emerald-50 text-emerald-500',

    templatestring: '{{ toString facilitator }}',
  },
  {
    id: 'My Name -- First Name',
    text: 'My Name -- First Name',
    class: 'bg-emerald-50 text-emerald-500',
    templatestring: '{{ facilitator.firstName }}',
  },
  {
    id: 'Person A -- Full Name',
    text: 'Person A -- Full Name',
    class: 'bg-blue-50 text-blue-500',
    templatestring: '{{ toString contacts.[0] }}',
  },
  {
    id: 'Person A -- First Name',
    text: 'Person A -- First Name',
    class: 'bg-blue-50 text-blue-500',
    templatestring: '{{ contacts.[0].firstName }}',
  },
  {
    id: 'Person B -- Full Name',
    text: 'Person B -- Full Name',
    class: 'bg-purple-50 text-purple-500',

    templatestring: '{{ toString contacts.[1] }}',
  },
  {
    id: 'Person B -- First Name',
    text: 'Person B -- First Name',
    class: 'bg-purple-50 text-purple-500',

    templatestring: '{{ contacts.[1].firstName }}',
  },
] as Option[];

const DynamicDataButton = ({ editor }) => {
  // State to manage the dropdown visibility
  const [isDropdownOpen, setDropdownOpen] = useState(false);

 

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  // Function to handle selection from the dropdown and insert dynamic content
  const selectOption = (option: Option) => {
    setDropdownOpen(false);

    // Insert a button with dynamic content into the editor
    editor.commands.insertDynamicData(option);
  };

  return (
    <div  id='dynamic-data-button' className="relative">
      <button
        onClick={() => {
          toggleDropdown(); // Toggle dropdown visibility
        }}
        className="flex items-center space-x-2 px-4 py-2 rounded-md border-2 border-transparent text-sm font-semibold hover:bg-gray-100 transition-all duration-200 text-blue-600"
      >
        <Icon
          icon={<SparklesIcon />}
          iconClass="w-5 h-5"
          className="text-current"
        />
        <span className='hidden sm:inline'>Insert Dynamic Data</span>
      </button>

      {/* Dropdown menu */}
      {isDropdownOpen && (
        <div className="absolute right-0 bottom-16 mt-2 w-48 bg-white shadow-[0_0_20px_rgba(0,0,0,0.2)] rounded-md z-10">
          <ul className="py-2">
            {OPTIONS.map((o) => (
              <li
                key={o.text}
                onClick={() => selectOption(o)}
                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
              >
                {o.text}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DynamicDataButton;
