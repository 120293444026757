import Status, { STATUS } from 'common';
import { ReactNode } from 'react';

interface Props {
  reason: string;
  icon: ReactNode;
  title: string;
  status: string;
  lastUpdatedAt: string;
}

export default function Caboose({ reason, icon, title, status, lastUpdatedAt }: Props) {
  const resolvedStatus = STATUS[status];

  return (
    <>
    <div className='w-4 h-1 border-dashed border-t-2 my-auto mx-2 border-gray-500'/>
    <div
      className={`${resolvedStatus.lightColor}  items-center inline-flex space-x-4 border-gray-300 border rounded-lg px-2 w-fit`}
    >
      <div className={`w-4 h-5 ${resolvedStatus.textDarkColor}`}>{icon}</div>
      <div className={`${resolvedStatus.textDarkColor}`}>
        <p>{title} </p>
        <p>{reason}</p>
      </div>
      <p className={`h-5 min-w-fit my-auto px-2 rounded-lg ${resolvedStatus.darkColor} ${resolvedStatus.textLightColor} `}>{new Date(lastUpdatedAt).toDateString()}</p>
    </div>
    </>
  );
}
