import { STATUS, getReminderDelay } from 'common';

interface Props {
  feedback: any;
  status: string;
}

export default function DateBadge({ feedback, status }: Props) {
  const deadline = new Date(
    new Date(feedback.createdAt).getTime() + getReminderDelay(feedback.lastCompletedState)
  );
  const isInThePast = new Date() > deadline;

  return (
    <>
      {isInThePast && (
        <p className="bg-green-300 rounded-lg px-2 py-1 text-green-700">
          Sent - {deadline.toDateString()}
        </p>
      )}
      {!isInThePast && status !== STATUS.PAUSED.id && (
        <p className="bg-blue-300 rounded-lg px-2 py-1 text-blue-700">
          Scheduled - {deadline.toDateString()}
        </p>
      )}

      {!isInThePast && status === STATUS.PAUSED.id && (
        <p className="bg-yellow-300 rounded-lg px-2 py-1 text-yellow-700">
          Scheduled - {deadline.toDateString()}
        </p>
      )}
    </>
  );
}
