import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { UserPlusIcon } from '@heroicons/react/24/solid';
import avatar from '../icon/home/avatar.svg';

/**
 * Renders the AddNewContact component.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.constructInput - The function to construct input elements.
 * @param {Function} props.submitForm - The function to submit the form.
 * @param {Object} props.inputs - The input values.
 * @param {Object} props.contact - The contact object.
 * @returns {JSX.Element} The rendered AddNewContact component.
 */
function AddNewContact({
  constructInput,
  submitForm,
  inputs,
  contact,
  isTutorialRunning,
}) {
  const [contactCounter, setContactCounter] = useState({
    hasContacts: false,
    contacts: 0,
    loading: true,
  });

  const { contacts, loading } = contactCounter;
  const createFirstContact = `Lets create your first contact - in the next minute, we'll be making an introduction for them.`;
  const createSecondContact = `Now, let’s add a second contact which you want to introduce to your first contact`;

  useEffect(() => {}, [loading]);

  return (
    <div className="new-contact">
      {!loading && contacts <= 1 && (
        <h1 className="new-contact__title">
          {contacts === 0 && createFirstContact}
          {contacts === 1 && createSecondContact}
        </h1>
      )}
      <div className="new-contact__container">
        <img className="new-contact__container__img" src={avatar} alt="Icon" />
      </div>
      {contact.isBlacklisted && (
        <div className="bg-red-500 rounded px-4 text-center py-2 my-4 ">
          {' '}
          This Contact Has Opted out of Introductions{' '}
        </div>
      )}
      <form className="new-contact__form">
        <div className="new-contact__form__group one">
          {constructInput(inputs.firstName)}
        </div>
        <div className="new-contact__form__group two">
          {constructInput(inputs.lastName)}
        </div>
        <div className="new-contact__form__group five">
          {constructInput(inputs.email)}
        </div>
        <div className="new-contact__form__button six">
          <button
            type="button"
            className="button__primary bg-emerald-600"
            onClick={submitForm}
          >
            <UserPlusIcon className="w-5 h-4" />
            {isTutorialRunning
              ? 'Next'
              : contact.email === undefined
              ? 'Save Contact'
              : 'Save Changes'}
          </button>
        </div>
      </form>
    </div>
  );
}

AddNewContact.defaultProps = {
  contact: {
    firstName: '',
    lastName: '',
    email: '',
    occupation: '',
    additionalNotes: '',
  },
};

AddNewContact.propTypes = {
  constructInput: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  contact: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    occupation: PropTypes.string,
    additionalNotes: PropTypes.string,
  }),
};

export default AddNewContact;
