import { EditContact, CreateContact } from 'common';
import axios, { AxiosInstance } from 'axios';

export default class ContactService {
  apiUrl: string;
  axiosInstance: AxiosInstance;

  constructor(
    apiUrl: string,
    applyInterceptors: (axiosInstance: AxiosInstance) => void
  ) {
    this.apiUrl = apiUrl;
    // Create an Axios instance
    this.axiosInstance = axios.create({
      baseURL: this.apiUrl,
    });
    // Apply interceptors
    applyInterceptors(this.axiosInstance);
  }

  getToken = () => {
    return JSON.parse(localStorage.getItem('tokens') as string).id_token;
  };

  list = async (filter?: string): Promise<any> => {
    let url = 'api/contact';
  
    // If a filter is provided, append it as a query parameter to the URL
    if (filter) {
      url += `?filter=${encodeURIComponent(filter)}`;
    }
  
    const response = await this.axiosInstance.get(url, {
      headers: {
        Authorization: this.getToken(),
      },
    });
  
    return response.data;
  };

  download = async (): Promise<any> => {
    const response = await this.axiosInstance.get('api/contact/download', {
      headers: {
        Authorization: this.getToken(),
      },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');
    a.href = url;
    a.download = 'contacts.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  create = async (request: CreateContact): Promise<any> => {
    const response = await this.axiosInstance.post('api/contact', request, {
      headers: {
        Authorization: this.getToken(),
      },
    });
    return response.data;
  };

  edit = async (request: EditContact): Promise<any> => {
    const response = await this.axiosInstance.put('api/contact', request, {
      headers: {
        Authorization: this.getToken(),
      },
    });
    return response.data;
  };

  uploadCsv = async (file: File): Promise<any> => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await this.axiosInstance.post('api/contact/upload', formData, {
      headers: {
        Authorization: this.getToken(),
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };
}