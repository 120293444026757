import axios, { AxiosInstance } from 'axios';

/**
 * Layer to get UserData for FE
 */
export default class UserDataService {
  apiUrl: string;
  axiosInstance: AxiosInstance;

  constructor(
    apiUrl: string,
    applyInterceptors: (axiosInstance: AxiosInstance) => void
  ) {
    this.apiUrl = apiUrl;

    // Create an Axios instance
    this.axiosInstance = axios.create({
      baseURL: this.apiUrl,
    });

    // Apply interceptors
    applyInterceptors(this.axiosInstance);
  }

  getToken = () => {
    return JSON.parse(localStorage.getItem('tokens') as string).id_token;
  };

  get = async (): Promise<any> => {
    const response = await this.axiosInstance.get('api/user', {
      headers: {
        Authorization: this.getToken(),
      },
    });
    return response.data;
  };

  delete = async (): Promise<any> => {
    try {
      await this.axiosInstance.delete('api/user', {
        headers: {
          Authorization: this.getToken(),
        },
      });
      localStorage.clear();
      window.location.href = '/';
    } catch (e) {
      console.error(e);
    }
  };

  update = async (firstName: string, lastName: string): Promise<any> => {
    const response = await this.axiosInstance.post(
      'api/user',
      {
        firstName,
        lastName,
      },
      {
        headers: {
          Authorization: this.getToken(),
        },
      }
    );
    return response.data;
  };
}
