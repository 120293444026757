/* eslint-disable */
import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import Icon from './Icon';
import StatusBadge from './StatusBadge';
import EmailSentCard from './intro-activity/EmailSentCard';
import { groupFeedbackByAttempt } from '../Utilities';
import FeedbackCard from './intro-activity/FeedbackCard';
import { STATUS } from 'common';

/**
 * Intro Activity Modal used to focus on an introduction.
 */
export default function IntroActivityModal({
  isOpen,
  introduction,
  onClose,
  userData,
}) {
  const cancelButtonRef = useRef(null);
  const feedbacksByAttempt = groupFeedbackByAttempt(introduction);
  console.log(introduction.recipients)

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                id="intro-activity-modal"
                className="relative overflow-hidden text-left bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:w-full sm:max-w-lg"
              >
                <div className="float-right p-4">
                  <button type="button" onClick={onClose}>
                    <Icon
                      className="text-gray-600"
                      iconClass="text-gray-600 h-5 w-5"
                      icon={<XMarkIcon />}
                    />
                  </button>
                </div>
                <div className="container p-6 mx-auto space-y-3">
                  <span className="flex space-x-4">
                    <h2 className="mt-1 text-lg font-bold">Intro Activity</h2>
                    <StatusBadge status={STATUS[introduction.introStatus]} />
                  </span>
                  <div className="mt-4 mb-2 border border-t-0 border-gray-200 rounded" />

                  <p className="font-bold text-black">
                    {introduction.recipients
                      .map((r) => `${r.firstName} ${r.lastName}`)
                      .join(' and ')}
                    !
                  </p>
                  <div className="flex w-24 mt-6">
                    {introduction.recipients.map((recipient, i) => (
                      <Fragment key={recipient.id || i}>
                        {i > 0 && (
                          <p className="pl-2 pr-2 text-gray-500">----</p>
                        )}
                        <div className="flex items-center justify-center w-6 h-6 text-white bg-green-600 rounded-full">
                          {recipient.firstName[0]}
                        </div>
                      </Fragment>
                    ))}
                  </div>

                  <div className="mt-4 mb-2 border border-t-0 border-gray-200 rounded" />

                  <EmailSentCard
                    introduction={introduction}
                    userData={userData}
                  />

                  <div>
                    {feedbacksByAttempt.map((feedbacks, index) => (
                      <div className='space-y-4'>
                        <h1>Attempt {index + 1}  - Completed Feedback: ({feedbacks.filter( f => f.fulfilled).length}/{feedbacks.length})</h1><div className='space-y-4'>
                        {feedbacks.map((f) => (
                          <FeedbackCard feedback={f} contact={introduction.recipients.find( r => r.id === f.recipient)}/>
                        ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
