import { useState } from 'react';
import Icon from '../Icon';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

interface Props {
  introduction: any;
  userData: any;
}

export default function EmailSentCard({ introduction, userData }: Props) {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div className="flex justify-between items-center">
        <h3>Email Sent</h3>
        <button onClick={() => setOpen(!isOpen)}>
          <Icon
            className="text-gray-600"
            iconClass="text-gray-600 h-5 w-5"
            icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          />
        </button>
      </div>

      {isOpen && (
        <div className="space-y-4">
          <p>
            From: <span className="text-blue-600">{userData.email}</span>
          </p>
          <p>
            to:{' '}
            <span className="text-blue-600">
              {introduction.recipients.map((r) => r.email).join('; ')}
            </span>
          </p>

          <div className="font-bold">Subject</div>
          <p className="border border-gray-200 p-4 rounded-lg">
            {introduction.subject}
          </p>
          <div className="font-bold">Body</div>
          <p
            className="border border-gray-200 p-4 rounded-lg"
            dangerouslySetInnerHTML={{ __html: introduction.body }}
          ></p>
          <p></p>
        </div>
      )}
    </>
  );
}
