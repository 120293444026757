import {
  BoldIcon,
  ItalicIcon,
  LinkIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  SparklesIcon,
} from '@heroicons/react/24/solid';
import { useCallback } from 'react';
import Icon from '../Icon';
import DynamicDataButton from './DynamicData';

export const MenuBar = ({ editor, isSubject }) => {
  if (!editor) {
    return null;
  }

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('Add a URL', previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();

      return;
    }

    // update link
    try {
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    } catch (e) {
      console.error(e);
    }
  }, [editor]);

  console.log(isSubject);
  return (
    <div className="control-group">
      <div className="button-group flex sm:space-x-4 space-x-0">
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            isSubject || !editor.can().chain().focus().toggleBold().run()
          }
          className={`flex items-center space-x-2 px-4 py-2 rounded-md border-2 border-transparent text-sm font-semibold transition-all duration-200 
            ${
              editor.isActive('bold')
                ? 'bg-gray-700 text-white'
                : 'hover:bg-gray-100 text-gray-700'
            }`}
        >
          <Icon
            icon={<BoldIcon />}
            iconClass="w-5 h-5"
            className="text-current"
          />
          <span className="hidden sm:inline">Bold</span>
        </button>

        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={
            isSubject || !editor.can().chain().focus().toggleItalic().run()
          }
          className={`flex items-center space-x-2 px-4 py-2 rounded-md border-2 border-transparent text-sm font-semibold transition-all duration-200 
            ${
              editor.isActive('italic')
                ? 'bg-gray-700 text-white'
                : 'hover:bg-gray-100 text-gray-700'
            }`}
        >
          <Icon
            icon={<ItalicIcon />}
            iconClass="w-5 h-5"
            className="text-current"
          />
          <span className="hidden sm:inline">Italic</span>
        </button>

        <button
          disabled={isSubject}
          hidden={editor.isActive('link')}
          onClick={setLink}
          className={`flex items-center space-x-2 px-4 py-2 rounded-md border-2 border-transparent text-sm font-semibold transition-all duration-200 
            ${editor.isActive('link') ? 'hidden' : 'text-black'}`}
        >
          <Icon
            icon={<LinkIcon />}
            iconClass="w-5 h-5"
            className="text-current"
          />
          <span className="hidden sm:inline">Link</span>
        </button>

        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
          className="flex items-center space-x-2 px-4 py-2 rounded-md border-2 border-transparent text-sm font-semibold hover:bg-gray-100 text-gray-700 transition-all duration-200"
        >
          <Icon
            icon={<ArrowUturnLeftIcon />}
            iconClass="w-5 h-5"
            className="text-current"
          />
          <span className="hidden sm:inline">Undo</span>
        </button>

        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
          className="flex items-center space-x-2 px-4 py-2 rounded-md border-2 border-transparent text-sm font-semibold hover:bg-gray-100 text-gray-700 transition-all duration-200"
        >
          <Icon
            icon={<ArrowUturnRightIcon />}
            iconClass="w-5 h-5"
            className="text-current"
          />
          <span className="hidden sm:inline">Redo</span>
        </button>

        <DynamicDataButton editor={editor} />
      </div>
    </div>
  );
};
