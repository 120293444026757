import React, { Fragment, useState, useEffect, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Icon from './Icon';

/**
 * Modal Displayed when creating a contact
 */
interface Props {
  text: ReactNode;
  icon: ReactNode;
  onClose: () => void;
}
export default function ActionConfirmModal({ text, icon, onClose }: Props) {
  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duraion-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative overflow-hidden text-left bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:w-1/3 sm:max-w-3xl">
                <div className={'pt-10 mx-auto space-y-4 pb-6 px-4'}>
                  {icon}
                  
                  <p className="text-center">{text} </p>
                  <div className="w-full flex justify-end">
                    <button 
                    onClick={onClose}
                    className="bg-emerald-500 rounded-lg w-16 h-10 text-white mr-10">
                      Ok
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
