import axios, { AxiosInstance } from 'axios';
import { SendIntroduction } from 'common';

export default class IntroductionService {
  apiUrl: string;
  axiosInstance: AxiosInstance;

  constructor(
    apiUrl: string,
    applyInterceptors: (axiosInstance: AxiosInstance) => void
  ) {
    this.apiUrl = apiUrl;

    // Create an Axios instance
    this.axiosInstance = axios.create({
      baseURL: this.apiUrl,
    });

    // Apply interceptors
    applyInterceptors(this.axiosInstance);
  }

  getToken = () => {
    return JSON.parse(localStorage.getItem('tokens') as string).id_token;
  };

  list = async (req?: { contact: string, filter: string }): Promise<any> => {
    // Extract contact and filter from the request, default to undefined if not provided
    const contact = req?.contact || undefined;
    const filter = req?.filter || undefined;
  
    // Construct the URL with the filter and contact parameters if they are present
    let url = 'api/introduction';
  
    // Append the parameters to the URL if they are defined
    const params: Record<string, string> = {};
    if (filter) {
      params.filter = filter;
    }
    if (contact) {
      params.contact = contact;
    }
  
    // If there are parameters, append them to the URL
    if (Object.keys(params).length > 0) {
      const queryString = new URLSearchParams(params).toString();
      url += `?${queryString}`;
    }
  
    console.log(url);
  
    // Send the GET request with the contact and filter as part of the URL
    const response = await this.axiosInstance.get(url, {
      headers: {
        Authorization: this.getToken(),
      },
    });
  
    return response.data;
  };
  
  
  

  create = async (request: SendIntroduction): Promise<any> => {
    const response = await this.axiosInstance.post(
      'api/introduction',
      request,
      {
        headers: {
          Authorization: this.getToken(),
        },
      }
    );
    return response.data;
  };

  toggleStatus = async (id: string, newStatus: string): Promise<any> => {
    const response = await this.axiosInstance.post(
      `api/introduction/${id}/status`,
      {
        status: newStatus,
      },
      {
        headers: {
          Authorization: this.getToken(),
        },
      }
    );
    return response.data;
  };

  changeStatus = () => {
    return {};
  };
}
