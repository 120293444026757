/**
 * Quick way to check if string is empty or blank.
 * @param {string} str
 */
const emptyOrBlank = (str) => {
  /* eslint-disable */
  if (str === undefined || str === null || str.length === 0) {
    return 'is Blank!';
  }

  return false;
};

export function groupFeedbackByAttempt(introduction: any): any[][] {
  // Get all feedback arrays and flatten them into a single array
  const allFeedback = Object.values(introduction.feedback).flat();

  // Group feedback by attempt number
  const groupedByAttempt = allFeedback.reduce(
    (acc: { [key: number]: any[] }, item: any) => {
      if (!acc[item.attempt]) {
        acc[item.attempt] = [];
      }
      acc[item.attempt].push(item);
      return acc;
    },
    {}
  );

  // Convert the object into an array of arrays, maintaining the attempt order
  const maxAttempt = Math.max(...Object.keys(groupedByAttempt).map(Number));
  const feedbackAttempts: any[][] = [];

  for (let i = 0; i <= maxAttempt; i++) {
    if (groupedByAttempt[i]) {
      feedbackAttempts.push(groupedByAttempt[i]);
    }
  }

  return feedbackAttempts;
}

export default emptyOrBlank;
