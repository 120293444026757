import React from "react";

type Props = {
  status: any
};

export default function StatusBadge({ status }: Props) {
  const { text, darkColor, textDarkColor, lightColor, textLightColor } = status;

  return (
    <div
      className={`
        ${textDarkColor}
        ${darkColor}
        inline-block px-4 py-2 rounded-lg font-bold text-sm shadow-md transition-transform duration-200 ease-in-out cursor-default
        `}
    >
      {text}
    </div>
  );
}
