import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import emptyOrBlank from '../../Utilities';
import UpdateUserInformation from './UpdateUserInformation';
import CreateContactForm from './CreateContactForm';
import SelectContactsPane from '../SelectContactsPane';
import ComposeMessageModal from '../ComposeMessageModal';
import { Contact } from 'common/model';
import TEMPLATE_BODY from '../../resources/DefaultEmailBody.txt';
import TEMPLATE_SUBJECT from '../../resources/DefaultEmailSubject.txt';
import CongratulateScreen from './CongratulateScreen';
import { userInfo } from 'os';
import GreetUser from './GreetUser';
import WelcomeToDashboard from './WelcomeToDashboard';

/**
 * Modal Displayed when creating a contact
 */
interface Props {
  userData: any;
  contacts: Contact[];
  updateUserData: (firstName, lastName) => void;
  setShowSpinner: (a) => void;
  onCreateContact: (a, b, c) => void;
  submitIntroduction: (a, b, c, cb?) => void;
  onFinishOnboarding: () => void;
}
export default function OnboardingModal({
  userData,
  contacts,
  setShowSpinner,
  onFinishOnboarding,
  onCreateContact,
  updateUserData,
  submitIntroduction,
}: Props) {
  enum State {
    INITIAL,
    GREETING,
    CREATE_FIRST_CONTACT,
    CREATE_SECOND_CONTACT,
    CHOOSE_CONTACTS,
    COMPOSE_INTRO,
    CONGRATULATE,
    START_DASHBOARD_TOUR,
    DONE,
  }
  const [selectedContacts, setSelectedContacts] = useState(
    [] as unknown as Contact[]
  );
  const [state, setState] = useState(State.INITIAL);
  const [isOpen, setOpen] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [templateText, setTemplateText] = useState('');
  const [templateSubject, setTemplateSubject] = useState('');

  const onClose = () => {
    console.log('close');
  };

  const onSubmitUserData = async (firstName: string, lastName: string) => {
    if (emptyOrBlank(firstName) || emptyOrBlank(lastName)) {
      return;
    }

    await updateUserData(firstName, lastName);

    setState(State.GREETING);
  };

  const createContact = (
    firstName: string,
    lastName: string,
    email: string,
    newState?: State
  ) => {
    if (
      emptyOrBlank(firstName) ||
      emptyOrBlank(lastName) ||
      emptyOrBlank(email)
    ) {
      return;
    }

    onCreateContact(firstName, lastName, email);
    if (newState) setState(newState);
  };

  /**
   * When we finish selection of two contacts
   */
  const onFinishSelection = (contactOne, contactTwo) => {
    setSelectedContacts([contactOne, contactTwo]);
    setState(State.COMPOSE_INTRO);
  };

  const onSkip = () => {
    setState(State.DONE);
    setOpen(false);
  };

  const handleTemplateCalls = async () => {
    let subjectText;
    let bodyText;

    // Fetch data when no response is available
    const subjectResponse = await fetch(TEMPLATE_SUBJECT);
    subjectText = await subjectResponse.text();

    const bodyResponse = await fetch(TEMPLATE_BODY);
    bodyText = await bodyResponse.text();

    setTemplateSubject(subjectText);
    setTemplateText(bodyText);
  };

  useEffect(() => {
    if (loaded) return;
    handleTemplateCalls();
    setLoaded(true);
  });

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duraion-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative overflow-hidden text-left bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                {state == State.INITIAL && (
                  <UpdateUserInformation
                    userData={userData}
                    onClick={onSubmitUserData}
                  />
                )}
                {state == State.GREETING && (
                  <GreetUser
                    userData={userData}
                    onContinue={() => setState(State.CHOOSE_CONTACTS)}
                    onSkip={() => setState(State.DONE)}
                  />
                )}
                {state == State.CREATE_FIRST_CONTACT && (
                  <CreateContactForm
                    onSubmit={(firstName, lastName, email) =>
                      createContact(
                        firstName,
                        lastName,
                        email,
                        State.CREATE_SECOND_CONTACT
                      )
                    }
                    onSkip={onSkip}
                    showHelp={true}
                    title={
                      <>
                        <h2>
                          Great to have you here, {`${userData.firstName}`}!
                        </h2>
                        <h2>
                          To kick things off, add a{' '}
                          <span className="text-emerald-600">
                            first contact
                          </span>{' '}
                          you want to connect with others.
                        </h2>
                      </>
                    }
                  />
                )}
                {state == State.CREATE_SECOND_CONTACT && (
                  <CreateContactForm
                    onSubmit={(firstName, lastName, email) =>
                      createContact(
                        firstName,
                        lastName,
                        email,
                        State.CHOOSE_CONTACTS
                      )
                    }
                    onSkip={onSkip}
                    title={
                      <>
                        <h2>
                          Awesome! Now, let's add a{' '}
                          <span className="text-emerald-600">
                            second contact
                          </span>{' '}
                          you'd like to connect them with.
                        </h2>
                      </>
                    }
                    showHelp={false}
                  />
                )}
                {state == State.CHOOSE_CONTACTS && (
                  <div className="p-4">
                    <SelectContactsPane
                      showHelp={true}
                      title={
                        'Well done! Now, in order to create an introduction, select two contacts below'
                      }
                      open={true}
                      contacts={contacts}
                      onNewContactSubmit={() => {}}
                      onFinishSelection={onFinishSelection}
                    />
                  </div>
                )}

                {state == State.COMPOSE_INTRO && (
                  <div className="p-4">
                    <ComposeMessageModal
                      title="To finish the connection, review the email and click 'Send Intro'."
                      showTutorial={true}
                      userData={userData}
                      selectedContacts={selectedContacts}
                      onComposeSubmit={async (
                        selectedContacts,
                        subject,
                        content
                      ) => {
                        submitIntroduction(
                          selectedContacts,
                          subject,
                          content,
                          () => setState(State.CONGRATULATE)
                        );
                      }}
                      defaultBody={templateText}
                      defaultSubject={templateSubject}
                      onGoBack={() => {
                        setState(State.CHOOSE_CONTACTS);
                      }}
                      onClose={() => {}}
                      setShowSpinner={setShowSpinner}
                      setIntroModal={() => {}}
                    />
                  </div>
                )}

                {state == State.CONGRATULATE && (
                  <div className="p-4">
                    <CongratulateScreen
                      selectedContacts={selectedContacts}
                      onClick={() => {
                        setState(State.DONE);
                        setOpen(false);

                        setTimeout(() => {
                          setState(State.START_DASHBOARD_TOUR);
                          setOpen(true);
                        }, 2000);
                      }}
                      userData={userData}
                    />
                  </div>
                )}

                {state == State.START_DASHBOARD_TOUR && (
                  <div className="p-4">
                    <WelcomeToDashboard
                      onContinue={() => {
                        setState(State.DONE);
                        setOpen(false);
                        onFinishOnboarding();
                      }}
                      onSkip={() => {
                        setState(State.DONE);
                        setOpen(false);
                      }}
                    />
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
