import React, { Fragment, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Dialog, Transition } from '@headlessui/react';

import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth/lib/types';
import SelectContactsPane from './SelectContactsPane';
import ComposeMessageModal from './ComposeMessageModal';
import TemplateService from '../service/TemplateService';
import context from '../exports.json';
import AuthService from '../service/AuthService';
import { addAuthInterceptor } from '../service/AuthInterceptor';
import TEMPLATE_BODY from '../resources/DefaultEmailBody.txt';
import TEMPLATE_SUBJECT from '../resources/DefaultEmailSubject.txt';
import * as queries from '../graphql/queries';
import Icon from './Icon';
/**
 * Used when user wants to crete Intro
 */
export default function IntroModal({
  userData,
  isOpen,
  onClose,
  contacts,
  onNewContactSubmit,
  onIntroEditorSubmit,
  setIntroModal,
  setShowSpinner,
}) {
  const [templateText, setTemplateText] = useState('');
  const [templateSubject, setTemplateSubject] = useState('');
  const [currentPane, setCurrentPane] = useState('select-contacts');
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [showSelectContacts, setShowSelectContacts] = useState(true);
  const [showComposeIntro, setShowComposeIntro] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const authService = new AuthService(context.ApiGatewayUrl);
  const templateService = new TemplateService(
    context.ApiGatewayUrl,
    addAuthInterceptor(authService)
  );

  /**
   * When we finish selection of two contacts
   */
  const onFinishSelection = (contactOne, contactTwo) => {
    setSelectedContacts([contactOne, contactTwo]);
    setCurrentPane('compose-intro');
  };

  /**
   * Clear the state, but call the callback.
   */
  const cleanSelectedStateAndSubmit = (submittedContacts, subject, body) => {
    setSelectedContacts([]);
    setCurrentPane('select-contacts');
    onIntroEditorSubmit(submittedContacts, subject, body);
  };

  const handleTemplateCalls = async () => {
    const response = await templateService.list();

    let subjectText;
    let bodyText;
    let templateId;

    if (response.length === 0) {
      // Fetch data when no response is available
      const subjectResponse = await fetch(TEMPLATE_SUBJECT);
      subjectText = await subjectResponse.text();

      const bodyResponse = await fetch(TEMPLATE_BODY);
      bodyText = await bodyResponse.text();
    } else {
      // Use data from the response when available
      subjectText = response[0].subject;
      bodyText = response[0].body;
      templateId = response[0].id;
    }

    setTemplateSubject(subjectText);
    setTemplateText(bodyText);
  };

  useEffect(() => {
    if (loaded) return;
    handleTemplateCalls();
    setLoaded(true);
  });

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={isOpen}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative overflow-hidden text-left bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:w-full sm:max-w-3xl custom-mobile-modal">
                <div className="float-right p-4">
                  <button type="button" onClick={onClose}>
                    <Icon
                      className="text-gray-600"
                      iconClass="text-gray-600 h-5 w-5"
                      icon={<XMarkIcon />}
                    />
                  </button>
                </div>
                <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                  {currentPane === 'select-contacts' && (
                    <SelectContactsPane
                      title={'Create New Intro'}
                      open={showSelectContacts}
                      contacts={contacts}
                      onNewContactSubmit={onNewContactSubmit}
                      onGoForward={() => setCurrentPane('compose-intro')}
                      onFinishSelection={onFinishSelection}
                    />
                  )}
                  {currentPane === 'compose-intro' && (
                    <ComposeMessageModal
                      title='Approve your intro email'
                      userData={userData}
                      open={showComposeIntro}
                      selectedContacts={selectedContacts}
                      onComposeSubmit={cleanSelectedStateAndSubmit}
                      defaultBody={templateText}
                      defaultSubject={templateSubject}
                      onGoBack={() => setCurrentPane('select-contacts')}
                      onClose={() => onClose()}
                      setShowSpinner={setShowSpinner}
                      setIntroModal={setIntroModal}
                    />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

IntroModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  contacts: propTypes.arrayOf(propTypes.shape({})).isRequired,
  onNewContactSubmit: propTypes.func.isRequired,
  onIntroEditorSubmit: propTypes.func.isRequired,
  setIntroModal: propTypes.func.isRequired,
  setShowSpinner: propTypes.func.isRequired,
};
