import React from 'react';
import PropTypes from 'prop-types';
import { UserGroupIcon } from '@heroicons/react/24/solid';
import Icon from './Icon';

/**
 * Renders the home tabs component.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.tabs - The array of tabs.
 * @param {string} props.activeTab - The active tab.
 * @returns {JSX.Element} The rendered component.
 */
function HomeTabs({ tabs, activeTab }) {
  return (
    <ul className="home-table__card__tabs">
      {tabs.map((tab) => (
        <li
          className="home-table__card__tabs__item"
          key={Math.floor(Math.random() * 1000)}
        >
          <button
            type="button"
            href="#"
            className={`inline-block no-underline rounded-t-lg p-4 ${
              activeTab === tab.activeText
                ? 'border-b-2 border-blue-700 text-blue-700'
                : 'border-b text-gray-700'
            }`}
            aria-current={tab.current}
            onClick={tab.action}
          >
            <Icon
              icon={tab.icon}
              iconClass="w-5 h-5 mr-4"
              text={tab.name}
            />
          </button>
        </li>
      ))}
    </ul>
  );
}

HomeTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default HomeTabs;
