import { EnvelopeIcon } from "@heroicons/react/24/solid";

interface Props {
  createdAt: string;
}

export default function EmailSent({ createdAt }: Props) {
  return (
    <>
      <div className={`border border-gray-300 rounded-lg p-2 h-fit my-auto`}>
        <div className="inline-flex items-center space-x-2">
          {/* Add 'items-center' to align items vertically */}
          <div>
            <EnvelopeIcon className={`w-4 h-4`} />
          </div>
          <div>
            Email Sent
          </div>
          <p className="bg-green-300 text-green-700 rounded-lg px-2">
            {new Date(createdAt).toDateString()}
          </p>
        </div>
      </div>
    </>
  );
}
