import React, { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import context from '../exports.json';
import TemplateService from '../service/TemplateService';
import AuthService from '../service/AuthService';
import { addAuthInterceptor } from '../service/AuthInterceptor';

import { ArrowLongLeftIcon } from '@heroicons/react/24/solid';

import TEMPLATE_BODY from '../resources/DefaultEmailBody.txt';
import TEMPLATE_SUBJECT from '../resources/DefaultEmailSubject.txt';
import TipTap from './editor/TipTap';

/**
 * Default Introduction
 */
export default function DefaultIntroduction({ userData, onLoad = () => {}}) {
  const [subjectText, setSubjectText] = useState('');
  const [loaded, setLoaded] = useState(false);

  /**
   * Lazily Set the Editor State.
   */
  const [content, setContent] = useState('');

  const authService = new AuthService(context.ApiGatewayUrl);
  const templateService = new TemplateService(
    context.ApiGatewayUrl,
    addAuthInterceptor(authService)
  );
  const handleTemplateCalls = async () => {
    const response = await templateService.list();

    let subjectText;
    let bodyText;
    let templateId;

    if (!response.length) {
      // Fetch data when no response is available
      const subjectResponse = await fetch(TEMPLATE_SUBJECT);
      subjectText = await subjectResponse.text();

      const bodyResponse = await fetch(TEMPLATE_BODY);
      bodyText = await bodyResponse.text();
    } else {
      // Use data from the response when available
      subjectText = response[0].subject;
      bodyText = response[0].body;
      templateId = response[0].id;
      onLoad()
    }

    setSubjectText(subjectText);
    setContent(bodyText);
  };

  useEffect(() => {
    if (loaded) {
      return;
    }

    handleTemplateCalls();

    setLoaded(true);
  }, [loaded]);

  return (
    <div className="container mx-auto">
      {loaded && content && (
        <>
          <div className="rounded" />

          <div className="grid grid-cols-1">
            <div className="cols-span-4">
              <div className="float-left">
                <a
                  href="/app/home"
                  type="button"
                  className="flex px-4 py-4 mb-2 text-xs font-semibold text-emerald-700 bg-transparent rounded hover:bg-emerald-500 hover:text-white hover:border-transparent"
                >
                  <ArrowLongLeftIcon className="w-4 h-4 mr-2" />
                  Return to Home Page
                </a>
              </div>
            </div>
            <TipTap
              userData={userData}
              subject={subjectText}
              body={content}
              onSubmit={async (subject: string, body: string, cb) => {
                templateService.saveVersion('default', {
                  Body: body,
                  Subject: subject,
                });
                cb();
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}
