import { ClockIcon } from '@heroicons/react/24/solid';
import DateBadge from './DateBadge';
import { STATUS } from 'common';

interface Props {
  items: any[];
  status: string;
}

export default function Reminder({ items, status }: Props) {
  const statusColor =
    STATUS[status] == STATUS.DEAD ? STATUS[status].lightColor : '';
  const statusColorText =
    STATUS[status] == STATUS.DEAD ? STATUS[status].textDarkColor : '';

  return (
    <>
      <div className={`border border-gray-300 rounded-lg p-2 ${statusColor}`}>
        <div className="inline-flex items-center space-x-2">
          {/* Add 'items-center' to align items vertically */}
          <div>
            <ClockIcon className={`w-4 h-4 ${statusColorText}`} />
          </div>
          <div>
            <p className="font-medium">Feedback Request Email</p>
            <p className="text-sm text-gray-600">
              {items.filter((f) => f.fullfilled).length} / {items.length}
            </p>
          </div>
          <div className="space-y-2">
            {items.map((i) => (
              <DateBadge feedback={i} status={status} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
