import { CheckIcon } from '@heroicons/react/24/solid';
import context from '../../exports.json';
import { addAuthInterceptor } from '../../service/AuthInterceptor';
import SubscriptionService from '../../service/SubscriptionService';
import AuthService from '../../service/AuthService';

// PricingCard Component
export default function PricingCard({
  title,
  price,
  priceId,
  description,
  features,
  buttonClass,
  cardClass,
  goto,
  buttonText,
  disabled
}) {
  return (
    <div
      className={`text-center border bg-white ${cardClass} rounded-lg space-y-6`}
    >
      <h2 className="pt-8 text-xl font-bold">{title}</h2>
      <h2 className="text-4xl font-bold">{price}</h2>
      <h3 className="text-gray-500">{description}</h3>
      <button
        disabled={disabled}
        onClick={() => {
          goto();
        }}
        className={`w-11/12 max-w-72 mx-auto h-14 ${buttonClass} text-white rounded-lg flex items-center justify-center`}
      >
        {buttonText}
      </button>
      <ul className="text-left space-y-2 w-11/12 mx-auto pb-10">
        {features.map((f, index) => (
          <li key={index} className="flex items-center">
            <CheckIcon className="text-emerald-600 h-4 w-4 mr-2" />
            {f}
          </li>
        ))}
      </ul>
    </div>
  );
}

// Monthly Plan
export const MONTHLY = ({ goto, buttonText, disabled, current }) => (
  <PricingCard
    buttonText={buttonText}
    disabled={disabled}
    goto={() => goto(context.MonthlyPriceId)}
    title="Monthly Plan"
    buttonClass={current ? "bg-emerald-600" : "bg-black"}
    cardClass={current ? "border-emerald-700" : "border-gray-200"}
    price="$11"
    description="Billed at $11 per month"
    features={[
      'Unlimited Introductions',
      'Unlimited Feedback Loops',
      '10% discount per referred user',
      'Grandfather Pricing (Subscribe before we get more expensive!)',
    ]}
    priceId={context.MonthlyPriceId}
  />
);

// Annual Plan 
export const ANNUAL = ({ goto, buttonText, disabled, current }) => (
  <PricingCard
    buttonText={buttonText}
    goto={() => goto(context.YearlyPriceId)}
    title="Annual Plan"
    disabled={disabled}
    buttonClass={current ? "bg-emerald-600" : "bg-black"}
    cardClass={current ? "border-emerald-700" : "border-gray-200"}
    price="$111"
    description="Billed at $111 per year ($9.25 per month)"
    features={[
      'Same as the Monthly Plan, but...',
      'Early access to upcoming features',
      'Free consult from the founder', 
      'Free 1 hour VA support importing your first 100 contacts',
    ]}
    priceId={context.YearlyPriceId}
  />
);
