import { Node, mergeAttributes } from '@tiptap/core';
import { Option, OPTIONS } from './DynamicData';
import { UserIcon } from '@heroicons/react/24/solid';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    dynamicData: {
      /**
       * Insert a custom button
       */
      insertDynamicData: (option: Option) => ReturnType;
    };
  }
}

const DynamicDataButton = Node.create({
  name: 'customButton',

  group: 'inline',

  inline: true,

  atom: true, // Makes it a single unit

  addAttributes() {
    return {
      content: {
        default: '',
      },
      className: { default: '' },
      templatestring: { default: '' },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'button[data-type="templatestring"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    const option = OPTIONS.find(o => o.text == HTMLAttributes.content) as Option;
    const wrapper = document.createElement('span');
    wrapper.className =
      'inline-flex items-center gap-1 group px-2 py-1 mt-2 rounded-md text-sm font-medium transition-colors ' +
      option.class;

    // Create SVG icon
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('viewBox', '0 0 24 24');
    svg.setAttribute('width', '24');
    svg.setAttribute('height', '24');
    svg.setAttribute('fill', 'currentColor');
    svg.setAttribute('class', 'h-6 w-6 ' + option.class);

    // Add UserIcon path
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttribute(
      'd',
      'M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z'
    );
    svg.appendChild(path);

    // Main button
    const button = document.createElement('button');
    button.setAttribute('data-type', 'templatestring');
    button.setAttribute('disabled', 'true');
    button.setAttribute('templatestring', option.templatestring);
    button.setAttribute('content', option.text);
    button.textContent = option.text;

    wrapper.appendChild(svg);
    wrapper.appendChild(button);
    return wrapper;
  },

  addCommands() {
    return {
      insertDynamicData:
        (option: Option) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              content: option.text,
              className: option.class,
              templatestring: option.templatestring,
            },
          });
        },
    };
  },
});

export default DynamicDataButton;
