import React, { ReactNode, useState } from 'react';
import Footer from '../Footer';
import AccordionAccount from './AccordionAccount';
import BackButton from '../BackButton';
import DefaultIntroduction from '../DefaultIntroduction';
import context from '../../exports.json';
import AuthService from '../../service/AuthService';
import { redirect, useNavigate, useOutletContext } from 'react-router';
import {
  ArrowDownTrayIcon,
  ChatBubbleLeftIcon,
  CheckIcon,
  CreditCardIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  ShieldCheckIcon,
  ShoppingCartIcon,
  SparklesIcon,
  TrashIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/24/solid';
import { addAuthInterceptor } from '../../service/AuthInterceptor';
import SubscriptionService from '../../service/SubscriptionService';
import PersonalInformation from './PersonalInformation';
import { UserData } from 'common';
import emptyOrBlank from '../../Utilities';
import UserDataService from '../../service/UserDataService';
import ActionConfirmModal from '../ActionConfirmModal';
import PricingCard, { ANNUAL, MONTHLY } from '../pricing/PricingCard';
import ContactService from '../../service/ContactService';
import ConfirmNukeAccountModal from './ConfirmNukeAccountModal';
import Icon from '../Icon';
import Joyride from 'react-joyride';
import { useSearchParams } from 'react-router-dom';
import Tooltip from '../tour/Tooltip';
import FinishedTutorialModal from '../onboarding/FinishedTutorialModal';

export default function Account() {
  const [searchParams] = useSearchParams();
  const isTutorialSearchParam = searchParams.has('isTutorial');
  const [displayFinishedTutorialModal, setDisplayFinishedTutorialModal] =
    useState(false);
  const [isTutorial, setTutorial] = useState(isTutorialSearchParam);
  const [stepIndex, setStepIndex] = useState(0);
  const authService = new AuthService(context.ApiGatewayUrl);
  const userDataService = new UserDataService(
    context.ApiGatewayUrl,
    addAuthInterceptor(authService)
  );
  const contactService = new ContactService(
    context.ApiGatewayUrl,
    addAuthInterceptor(authService)
  );
  const subscriptionService = new SubscriptionService(
    context.ApiGatewayUrl,
    addAuthInterceptor(authService)
  );

  const [tabStates, setTabStates] = useState({
    personalInfo: false,
    emailTemplates: false,
    dynamicData: false,
    plansBilling: false,
    accountInfo: false,
  });

  const toggleTab = (tab: keyof typeof tabStates) => {
    setTabStates((prev) => ({
      ...prev,
      [tab]: !prev[tab],
    }));
  };

  const [confirmNukeAccount, setConfirmNukeAccountModal] = useState(false);
  const [modal, setModal] = useState<
    { text: ReactNode; icon: ReactNode } | undefined
  >();

  const { userData, setUserData } = useOutletContext() as {
    userData: any;
    setUserData: (s: UserData) => void;
  };
  const navigate = useNavigate();

  const onSubmitUserData = async (firstName: string, lastName: string) => {
    if (emptyOrBlank(firstName) || emptyOrBlank(lastName)) return;

    await userDataService.update(firstName, lastName);
    setUserData({ ...userData, firstName, lastName });
    setModal({
      text: 'Your personal information has been updated successfully!',
      icon: (
        <Icon
          iconClass={'p-1 h-8 w-8 bg-emerald-500 text-white rounded-full'}
          icon={<CheckIcon />}
        />
      ),
    });
  };

  const downloadContact = async () => {
    await contactService.download();
  };

  const createdDate = new Date(userData.createdAt as string);
  const today = new Date();
  const diffDays =
    30 -
    Math.floor(
      (today.getTime() - createdDate.getTime()) / (1000 * 60 * 60 * 24)
    );
  const daysLeft = Math.max(0, Math.min(30, diffDays));

  const logOut = async () => {
    authService.logout();
    navigate('/');
  };

  const TABS = {
    'Personal Information': {
      disabled: false,
      key: 'personalInfo',
      icon: <UserIcon />,
      content: (
        <PersonalInformation userData={userData} onClick={onSubmitUserData} />
      ),
    },
    'Email Templates': {
      disabled: false,
      key: 'emailTemplates',
      icon: <EnvelopeIcon />,
      link: (
        <a className="text-blue-600" href="/app/intro/default">
          Default Introduction Template
        </a>
      ),
      content: (
        <div id="email-template-accordion" className="mx-9">
          <DefaultIntroduction userData={userData} />
        </div>
      ),
    },
    'Dynamic Data': {
      key: 'dynamicData',
      icon: <SparklesIcon />,
      disabled: true,
      link: <></>,
      content: <div className="mx-9">Coming Soon!</div>,
    },
    'Plans & Billing': {
      disabled: false,
      key: 'plansBilling',
      icon: <ShoppingCartIcon />,
      content: (
        <div className="">
          <p className="py-4">Manage your plan and billing history</p>
          <div className="mx-9 grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
            <div>
              <PricingCard
                disabled={true}
                buttonText={'Free Plan'}
                goto={() => {}}
                title="Free Plan"
                buttonClass="bg-black"
                cardClass="border-gray-200"
                price="Free"
                description={<TrialStatus daysLeft={daysLeft} />}
                features={['One Month of Introductions']}
                priceId={context.MonthlyPriceId}
              />
            </div>
            <div>
              <MONTHLY
                goto={() => subscriptionService.settings()}
                current={userData.subscriptionType === 'monthly'}
                disabled={userData.subscriptionType === 'monthly'}
                buttonText={
                  userData.subscriptionType === 'monthly'
                    ? 'Your Current Plan'
                    : 'Switch To Monthly'
                }
              />
            </div>
            <div>
              <ANNUAL
                goto={() => subscriptionService.settings()}
                current={userData.subscriptionType === 'yearly'}
                disabled={userData.subscriptionType === 'yearly'}
                buttonText={
                  userData.subscriptionType === 'yearly'
                    ? 'Your Current Plan'
                    : 'Switch to Yearly'
                }
              />
            </div>
          </div>
          <div className="mx-9 flex items-center justify-between">
            <span>
              Looop partners with Stripe to provide secure and seamless payment
              processing.
            </span>
            <div className="flex items-center gap-2">
              <span className="px-2 py-1 text-sm text-purple-600 bg-purple-100 border border-purple-200 rounded-lg">
                stripe
              </span>
              <button
                type="button"
                onClick={() => subscriptionService.settings()}
                className="bg-gradient-to-tl text-black bg-white border border-gray-300 rounded-lg px-4 py-2 flex items-center hover:bg-blue-200"
              >
                <CreditCardIcon className="flex w-5 h-5 mr-2" />
                Manage My Billing
              </button>
            </div>
          </div>
        </div>
      ),
    },
    'Account and Information': {
      disabled: false,
      key: 'accountInfo',
      icon: <ShieldCheckIcon />,
      content: (
        <div className="mx-9 space-y-6 text-sm">
          <div className="flex items-center justify-between">
            <div className="flex items-start space-x-4 text-gray-400">
              <EnvelopeIcon className="w-6 h-6 mt-1 " />
              <div>
                <h3 className="font-semibold">Email Templates</h3>
                <p className="">
                  Your personalized collection of email templates used for
                  introductions.
                </p>
              </div>
            </div>
            <div className="bg-yellow-200 rounded text-yellow-800 px-4 text-sm py-1">
              Coming Soon!
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-start space-x-4 text-gray-400">
              <ChatBubbleLeftIcon className="w-6 h-6 mt-1 " />
              <div>
                <h3 className="font-semibold">Introductions</h3>
                <p className="">
                  Your list of all introductions made, along with their current
                  statuses.
                </p>
              </div>
            </div>
            <div className="bg-yellow-200 rounded text-yellow-800 px-4 text-sm py-1">
              Coming Soon!
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-start space-x-4 text-gray-600">
              <UsersIcon className="w-6 h-6 mt-1" />
              <div>
                <h3 className="font-semibold">Contacts</h3>
                <p className="">
                  Your network of contacts that you can select for
                  introductions.
                </p>
              </div>
            </div>
            <button
              className="flex px-4 py-2 text-sm text-blue-500 border rounded-lg"
              onClick={downloadContact}
            >
              <ArrowDownTrayIcon className="flex w-5 h-5 mr-2" />
              Download Information
            </button>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-start space-x-4">
              <EnvelopeOpenIcon className="w-6 h-6 mt-1 text-gray-600" />
              <div>
                <h3 className="font-semibold">User Data</h3>
                <p className="text-gray-600">
                  Your personal information tied to your account and
                  preferences.
                </p>
              </div>
            </div>
            <div className="bg-yellow-200 rounded text-yellow-800 px-4 text-sm py-1">
              Coming Soon!
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-start space-x-4">
              <TrashIcon className="w-6 h-6 mt-1 text-gray-600" />
              <div>
                <h3 className="font-semibold">Delete My Account</h3>
                <p className="text-gray-600">
                  Ready to say goodbye? Delete your account securely.
                </p>
              </div>
            </div>
            <button
              className="px-4 py-2 text-sm bg-red-500 text-white rounded-lg"
              onClick={() => setConfirmNukeAccountModal(true)}
            >
              Delete My Account
            </button>
          </div>
        </div>
      ),
    },
  };

  const steps = [
    {
      data: {
        steps: 6,
        className: '',
      },
      floaterProps: {
        hideArrow: true,
        placement: 'top',
      },
      disableBeacon: true,
      target: '#accordion-container',
      content: (
        <>
          <p>
            Welcome to your Account Settings page. Here, you can manage your
            information, email templates, plans, payments, and more.
          </p>
        </>
      ),
    },
    {
      data: { steps: 6, className: '' },
      floaterProps: {
        hideArrow: true,
      },
      disableBeacon: true,
      target: '#subject-editor',
      content: (
        <>
          <p>
            With dynamic data tags, Looop automatically fills in your selected
            contacts' details, so you don’t have to.
          </p>
        </>
      ),
    },

    {
      data: {
        steps: 6,

        className: 'translate-y-32',
      },
      floaterProps: {
        hideArrow: true,
        placement: 'top',
      },
      disableBeacon: true,
      target: '#dynamic-data-button', // Selects the action column
      content: (
        <>
          <p>
            To add dynamic data, place your cursor in the text box, click
            'Insert Dynamic Data,' and choose the data to add.
          </p>
        </>
      ),
    },

    {
      data: {
        steps: 6,

        className: 'translate-y-32',
      },
      floaterProps: {
        hideArrow: true,
        placement: 'top',
      },
      disableBeacon: true,
      target: '#dynamic-data-button', // Selects the action column
      content: (
        <>
          <p>
            Want to delete dynamic data? Simply use the 'delete' or 'backspace'
            key, and it’s removed from the email.
          </p>
        </>
      ),
    },
    {
      data: {
        steps: 6,

        className: 'translate-y-32',
      },
      floaterProps: {
        hideArrow: true,
        placement: 'top',
      },
      disableBeacon: true,
      target: '#reset-to-default-button', // Selects the action column
      content: (
        <>
          <p>
            Need a fresh start? Simply click 'Reset to Default Template' to get
            back to the original version.
          </p>
        </>
      ),
    },
    {
      data: {
        steps: 6,

        className: 'translate-y-32',
      },
      floaterProps: {
        hideArrow: true,
        placement: 'top',
      },
      disableBeacon: true,
      target: '#submit-template-button', // Selects the action column
      content: (
        <>
          <p>
            Make sure to save your changes to apply them to future
            introductions!
          </p>
        </>
      ),
    },
  ];

  return (
    <>
      <Joyride
        stepIndex={stepIndex}
        run={isTutorial}
        callback={(data) => {
          if (
            data.action == 'close' &&
            data.lifecycle == 'complete' &&
            data.index === 0
          ) {
            setTabStates({ ...tabStates, emailTemplates: true });
          }

          if (
            data.action == 'close' &&
            data.lifecycle == 'complete' &&
            data.index === steps.length - 1
          ) {
            setTutorial(false);
            setDisplayFinishedTutorialModal(true);
            setTabStates({ ...tabStates, emailTemplates: true });
          }

          if (data.action == 'close' && data.lifecycle == 'complete') {
            setStepIndex(1 + stepIndex);
          }
        }}
        steps={steps as any}
        tooltipComponent={Tooltip}
      />
      {displayFinishedTutorialModal && (
        <FinishedTutorialModal
          onClick={() => {
            setDisplayFinishedTutorialModal(false);
          }}
        />
      )}
      {modal && (
        <ActionConfirmModal
          text={modal.text}
          icon={modal.icon}
          onClose={() => setModal(undefined)}
        />
      )}

      {confirmNukeAccount && (
        <ConfirmNukeAccountModal
          onClose={() => setConfirmNukeAccountModal(false)}
          onContinue={async () => {
            setConfirmNukeAccountModal(false);
            await userDataService.delete();
            redirect('/');
          }}
        />
      )}

      <div className="account">
        <BackButton path="/app/home" page="Home" />
        <div className="account__intro">
          <h2 className="account__intro__title">Account</h2>
          <h3 className="account__intro__description">
            Here you can manage your account and settings.
          </h3>
        </div>
        <div className="account__profile">
          <img
            className="account__profile__photo"
            src={userData.photoUrl as string}
            alt="profile"
          />
          <p className="text-xl font-bold">{`${userData.firstName} ${userData.lastName}`}</p>
          <p className="account__profile__email">{userData.email}</p>
        </div>
        <div className="container mx-auto mt-12" />
        <div
          id="accordion-container"
          className="container lg:w-3/4 sm:w-4/4 md:w-4/4 pt-8 mx-auto rounded-t-lg"
        >
          {Object.entries(TABS).map(([title, config], index) => (
            <AccordionAccount
              title={title}
              icon={config.icon}
              disabled={config.disabled}
              first={index === 0}
              isOpen={tabStates[config.key as keyof typeof tabStates]}
              onClick={() => toggleTab(config.key as keyof typeof tabStates)}
              key={config.key}
            >
              {config.content}
            </AccordionAccount>
          ))}
        </div>
        <div className="mt-6 mb-28">
          <button
            className="container flex justify-center w-3/4 h-8 p-1 mx-auto text-black bg-gray-300 hover:bg-blue-700 hover:text-white rounded-md"
            type="button"
            onClick={logOut}
          >
            Logout
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}

const TrialStatus = ({ daysLeft }: { daysLeft: number }) => (
  <div
    className={`rounded p-4 w-fit mx-auto text-white ${
      daysLeft === 0 ? 'bg-red-500' : 'bg-emerald-600'
    }`}
  >
    {daysLeft === 0 ? 'Free Trial ended' : `${daysLeft} Days Left`}
  </div>
);
