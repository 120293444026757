import React, { Fragment, useState, useEffect, ReactNode } from 'react';
import Handlebars from 'handlebars';
import { Dialog, Transition } from '@headlessui/react';
import Icon from '../Icon';
import { userInfo } from 'os';
import {
  CheckBadgeIcon,
  CheckCircleIcon,
  CheckIcon,
} from '@heroicons/react/24/solid';

/**
 * Modal Displayed when creating a contact
 */
interface Props {
  onClose: () => void;
}
export default function SaveModal({ onClose }: Props) {
  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duraion-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative overflow-hidden text-left bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:w-1/3 sm:max-w-1/3">
                <div className={'pt-10 mx-auto space-y-4 p-12'}>
                  <Icon
                    iconClass="text-emerald-600 h-10 w-10"
                    icon={<CheckCircleIcon />}
                  />

                  <p className="text-center">
                    Your email template has been updated successfully!
                  </p>
                </div>
                <button
                  className="float-right bg-emerald-600 rounded w-30 h-10 px-10 mr-4 mb-4 text-white"
                  onClick={onClose}
                >
                  Ok
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
