import {
  MagnifyingGlassIcon,
  FunnelIcon,
  XMarkIcon,
  ArrowUpTrayIcon,
  ArrowDownTrayIcon,
  CheckIcon,
} from '@heroicons/react/24/solid';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { STATUS } from 'common';
import StatusBadge from './StatusBadge';
import ContactService from '../service/ContactService';
import Icon from './Icon';
import ActionConfirmModal from './ActionConfirmModal';

/**
 * Renders the HomeSearch component.
 *
 * @returns {JSX.Element} The rendered HomeSearch component.
 */
interface Props {
  contactService: ContactService;
  onContactClick: (a) => {};
  setSearchBy: (a) => {};
  onFilterChange: (a) => {};
  onClear: () => void; // Added onClear prop

  showCSVOptions;
}

function HomeSearch({
  showCSVOptions,
  contactService,
  setSearchBy,
  onFilterChange,
  onContactClick,
  onClear, // Destructure onClear
}: Props) {
  const [inputValue, setInputValue] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [contacts, setContacts] = useState([] as unknown as any);
  const [loading, setLoading] = useState(false);

  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [modal, setModal] = useState(undefined as unknown as any);

  const onContactUploadFailure = (payload) => {
    setModal({
      icon: <Icon iconClass={'h-6 w-6 text-red-500'} icon={<XMarkIcon />} />,
      text: `Unable To Upload the Contacts: ${payload.message}. See below
      ${payload.errors.map( (e) => `Row: ${e.row}, Error: ${e.error}\n`)}
      `,
    });
  };
  const onContactUploadSuccess = () => {
    setModal({
      icon: (
        <Icon
          iconClass={'p-1 h-8 w-8 bg-emerald-500 text-white rounded-full'}
          icon={<CheckIcon />}
        />
      ),
      text: 'Successfully Uploaded Contacts',
    });
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.type !== 'text/csv') {
        alert('Please upload a CSV file');
        return;
      }

      setUploading(true);
      try {
        await contactService.uploadCsv(file);
        // Clear the input so the same file can be uploaded again if needed
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        onContactUploadSuccess();

        // Optionally refresh the contacts list or show success message
      } catch (err: any) {
        onContactUploadFailure(err.response.data);
        console.error('Upload failed:', err);
      } finally {
        setUploading(false);
      }
    }
  };

  /**
   * Handles the input change event.
   *
   * @param {Event} e - The input change event.
   */
  const handleInputChange = (e) => {
    const text = e.target.value;
    setInputValue(text);
    if (!text) {
      setSearchBy('');
      setContacts([]);
    }

    // Trigger the search only if the input is at least 3 characters long
    if (text.length >= 3) {
      fetchContacts(text);
    }
  };

  /**
   * Fetches contacts from the server.
   *
   * @param {string} query - The search query.
   */
  const fetchContacts = async (query) => {
    setLoading(true);

    // Stub the request: Simulate a delay and return mocked contact data

    // Filter contacts based on the query
    const filteredContacts = await contactService.list(query);

    setContacts(filteredContacts);
    setLoading(false);
  };

  const onSelectFilter = (status: any) => {
    toggleFilterDropdown();
    if (status) {
      setFilter(status.id);
      onFilterChange(status.id);
    } else {
      setFilter('');
      onFilterChange(undefined);
    }
  };

  /**
   * Toggles the filter dropdown.
   */
  const toggleFilterDropdown = () => {
    setIsFilterOpen((prev) => !prev);
  };

  /**
   * Converts the input text to lowercase.
   *
   * @param {string} text - The text to be converted to lowercase.
   * @returns {string} The lowercase version of the input text.
   */
  const toLowerCase = (text) => {
    if (typeof text === 'string' && text.length > 0) {
      return text.toLowerCase();
    }
    return text;
  };

  // Function to handle clearing the input and triggering onClear prop
  const clearInput = () => {
    setInputValue('');
    setContacts([]);
    onClear(); // Call the onClear function passed as a prop
  };

  return (
    <>
      {modal && (
        <ActionConfirmModal
          text={modal.text}
          icon={modal.icon}
          onClose={() => setModal(undefined)}
        />
      )}
      <div className="xs:justify-center grid sm:grid-cols-2 grid-cols-1">
        <input
          type="file"
          accept=".csv"
          className="hidden"
          ref={fileInputRef}
          onChange={handleFileUpload}
        />
        {showCSVOptions ? (
          <div className="h-fit items-center my-auto sm:flex grid grid-cols-2 pb-4">
            <div className="group relative">
              <button
                id="upload-csv"
                className="text-sm flex items-center mx-4 px-2 float-right border border-gray-200 rounded-lg h-16 m-auto"
                onClick={() => fileInputRef.current?.click()}
                disabled={uploading}
              >
                <Icon
                  iconClass="h-6 w-6 text-black mr-4"
                  icon={<ArrowUpTrayIcon />}
                />
                {uploading ? 'Uploading...' : 'Upload Contacts From CSV'}
              </button>
              <span className="invisible group-hover:visible absolute -top-10 left-1/2 -translate-x-1/2 px-2 py-1 bg-emerald-500 text-white text-xs rounded whitespace-nowrap">
                Expected format: Email,FirstName,LastName
              </span>
            </div>

            <button
              id="download-csv"
              className="text-sm flex items-center mx-4 px-2 float-right border border-gray-200 rounded-lg h-16 my-auto"
              onClick={() => contactService.download()}
            >
              <Icon
                iconClass="h-6 w-6 text-black mr-4"
                icon={<ArrowDownTrayIcon />}
              />
              Download Contacts To CSV
            </button>
          </div>
        ) : (
          <div className="md:ml-4 relative items-center max-w-xl mb-2 h-16 flex">
            <input
              onChange={handleInputChange}
              type="text"
              name="search"
              placeholder="Search"
              className="h-16 flex-1 border border-gray-300 rounded-l-lg py-2 pl-12 pr-4 text-sm font-semibold text-gray-800 focus:outline-none focus:ring-2 focus:ring-primary"
              value={inputValue}
            />
            <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-600 h-6 w-6" />

            {/* Clear button */}
            <button
              type="button"
              onClick={clearInput}
              className="bg-emerald-600 h-16 text-white py-2 px-4 rounded-r-lg text-sm font-medium hover:bg-emerald-700 focus:outline-none"
            >
              Clear
            </button>

            {inputValue.length >= 3 && (
              <div className="absolute left-0 top-16 mt-2 w-full bg-white shadow-lg rounded-lg border border-gray-200 z-10">
                {loading ? (
                  <div className="p-4 text-sm text-gray-600">Loading...</div>
                ) : (
                  <ul className="space-y-1">
                    {contacts.length > 0 ? (
                      contacts.map((contact) => (
                        <li key={contact.id}>
                          <button
                            className="text-sm w-full text-left px-4 py-2 border-b border-gray-100 hover:bg-gray-100"
                            onClick={() => {
                              setContacts([]);
                              setInputValue(contact.email);
                              onContactClick(contact);
                            }}
                          >
                            {`${contact.firstName} ${contact.lastName} - ${contact.email}`}
                          </button>
                        </li>
                      ))
                    ) : (
                      <div className="p-4 text-sm text-gray-600">
                        No contacts found
                      </div>
                    )}
                  </ul>
                )}
              </div>
            )}
            <div className="relative flex items-center justify-center ml-4">
              <button
                type="button"
                onClick={toggleFilterDropdown}
                className="border border-gray-100 h-16 text-black px-4 py-2 rounded-lg flex items-center space-x-2 hover:bg-gray-200"
              >
                <FunnelIcon className="h-5 w-5" />
                <span>Filter</span>
                {filter !== '' && <StatusBadge status={STATUS[filter]} />}
              </button>
              {isFilterOpen && (
                <div className="absolute sm:left-0  right-0 top-20 mt-2 w-56 bg-white shadow-lg rounded-lg border border-gray-200 z-10">
                  <div className="p-4">
                    <h4 className="text-sm font-semibold text-gray-700 mb-2">
                      Status
                    </h4>
                    <ul className="space-y-1">
                      {Object.values(STATUS).map((status) => (
                        <li key={status.id}>
                          <button
                            className={`text-sm w-full text-left rounded-lg px-4 py-2 ${status.darkColor} ${status.textLightColor} border border-gray-50`}
                            onClick={() => onSelectFilter(status)}
                          >
                            {status.text}
                          </button>
                        </li>
                      ))}
                      <li>
                        <button
                          className="text-sm w-full text-left rounded-lg px-4 py-2 text-white bg-gray-400 border border-gray-50"
                          onClick={() => onSelectFilter('')}
                        >
                          None
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

HomeSearch.propTypes = {
  setSearchBy: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired, // Added prop type for onClear
};

export default HomeSearch;
