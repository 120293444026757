export enum WorkflowState {
  ORIGINAL= "ORIGINAL",
  RESET = "RESET",
  FOLLOW_UP_ATTEMPT_1 = "FOLLOW_UP_ATTEMPT_1",
  FOLLOW_UP_ATTEMPT_2 = "FOLLOW_UP_ATTEMPT_2",
  FOLLOW_UP_ATTEMPT_3 = "FOLLOW_UP_ATTEMPT_3",
  STOPPED = "STOPPED",
}

export const reminderDelay: Record<WorkflowState, number> = {
  [WorkflowState.ORIGINAL]: 24 * 60 * 60 * 1000 * 14, // 14 Days hours,
  [WorkflowState.RESET]: 0,
  [WorkflowState.FOLLOW_UP_ATTEMPT_1]: 24 * 60 * 60 * 1000, // 24 hours
  [WorkflowState.FOLLOW_UP_ATTEMPT_2]: 48 * 60 * 60 * 1000, // 48 hours
  [WorkflowState.FOLLOW_UP_ATTEMPT_3]: 72 * 60 * 60 * 1000, // 72 hours
  [WorkflowState.STOPPED]: 0,
};

export function getReminderDelay(state: string): number {
  if (isWorkflowState(state)) {
    return reminderDelay[state];
  }
  throw new Error(`Invalid workflow state: ${state}`);
}

// Type guard to ensure string is valid WorkflowState
function isWorkflowState(state: string): state is WorkflowState {
  return Object.values(WorkflowState).includes(state as WorkflowState);
}