import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/solid';

import Datepicker from 'tailwind-datepicker-react';
import Logo from '../icon/Logo.png';
import Footer from './Footer';
import FeedbackService from '../service/FeedbackService';
import context from '../exports.json';
/**
 * Feedback describes the interaction of a previous Intro
 */
export default function Feedback() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const haveMetFromQueryString = searchParams.get('haveMet');
  const [haveMet, setHaveMet] = useState(haveMetFromQueryString);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [feedbackResponse, setFeedbackResponse] = useState(undefined);
  const feedbackService = new FeedbackService(context.ApiGatewayUrl);
  const [unsavedFeedback, setUnsavedFeedback] = useState({
    // Not Met
    haveMet,
    introExperience: undefined,
    isGoodFit: undefined,
    notes: undefined,
    // Met
    intentionToMeet: undefined,
    dateSet: undefined,
  });

  const datepickerOptions = {
    defaultDate: null,
    inputPlaceholderProp: 'Select the date to you are planning to meet.',
    title: 'Planned Meeting Date',
    minDate: new Date(),
    todayBtn: true,
    clearBtn: true,
    theme: {
      background: 'dark:bg-gray-200 dark:text-gray-700',
      text: 'dark:text-gray-700',
      input: 'pl-10'
    },
  };

  /**
   * Handle a Datepicker Change
   * @param {*} selectedDate The date selected
   */
  const handleDatepickerChange = (selectedDate) => {
    selectedDate.setUTCHours(17); // Mid-Day EST.

    setUnsavedFeedback({
      ...unsavedFeedback,
      dateSet: selectedDate,
    });
  };
  /**
   * Handle the datepicker close
   * @param {*} state the state of the datepicker
   */
  const handleDatepickerClose = (state) => {
    setShowDatePicker(state);
  };

  /* eslint-disable */
  useEffect(() => {
    if (feedbackResponse) {
      return;
    }

    feedbackService
      .get(searchParams.get('wfId'), searchParams.get('contactId'))
      .then((response) => {
        setFeedbackResponse(response);
      })
      .catch((err) => {
        navigate('/feedbackConfirm');
      });
  });

  /**
   * Toggle we have met
   */
  const toggleHasMet = () => {
    setHaveMet(!haveMet);
    const feedbackState = unsavedFeedback;
    feedbackState.haveMet = !haveMet;
    setUnsavedFeedback({ ...feedbackState });
  };

  /**
   * Set Intro Experience
   */
  const setIntroExperience = (status) => {
    const feedbackState = unsavedFeedback;
    feedbackState.introExperience = status;
    setUnsavedFeedback({ ...feedbackState });
    console.log(feedbackState);
  };

  /**
   * Set Intro notes
   */
  const setNotes = (event) => {
    const feedbackState = unsavedFeedback;
    feedbackState.notes = event.target.value;
    setUnsavedFeedback({ ...feedbackState });
  };

  /**
   * Set Intro fit
   */
  const setGoodFit = (isGoodFit) => {
    const feedbackState = unsavedFeedback;
    feedbackState.isGoodFit = isGoodFit;
    setUnsavedFeedback({ ...feedbackState });
  };

  /**
   * Set Intro intention
   */
  const setIntention = (intention) => {
    const feedbackState = unsavedFeedback;
    feedbackState.intentionToMeet = intention;
    setUnsavedFeedback({ ...feedbackState });
  };

  /**
   * Set Intro notes
   */
  const setDateSet = (event) => {
    const feedbackState = unsavedFeedback;
    feedbackState.dateSet = event.target.value;
    setUnsavedFeedback({ ...feedbackState });
  };

  const submitFeedback = async (introId, contactId, recipientId) => {

    await feedbackService.save(introId, contactId, recipientId, feedbackResponse.attempt, {
      ...feedbackResponse.feedback,
      ...unsavedFeedback,
    })

    navigate('/feedbackConfirm')
  };

  if (!feedbackResponse) {
    return <div/>;
  }

  const recipient = feedbackResponse.contacts.find( c => c.isRecipient);
  const contact = feedbackResponse.contacts.find( c => !c.isRecipient);
  const facilitator = feedbackResponse.facilitator;

  return (
    <div>
      <div className="container content-center w-32 mx-auto">
        <img src={Logo} className="w-full pt-8" alt="Loop Icon" />
      </div>
      <div className="container w-full sm:w-2/5 p-4 mx-auto mt-12 bg-white border border-gray-200 space-y-2 rounded-xl">
        <h1 className="text-2xl font-bold"> Intro Feedback Form </h1>
        <div className="mt-4 mb-4 border border-t-0 border-gray-200 rounded" />
        <p>
          Hi {`${recipient.firstName} ${recipient.lastName}`},
          how was your introduction to{' '}
          {`${contact.firstName} ${contact.lastName}`}?
        </p>
        <div className="flex">
          <div className="flex my-auto">
            <div className="flex items-center justify-center w-10 h-10 text-2xl text-white bg-black border-2 border-white rounded-full">
              {facilitator.firstName[0]}
            </div>
            <p className="p-2">
              {`${facilitator.firstName} ${facilitator.lastName}`}
            </p>
          </div>

          <div className="w-6 h-0 my-auto border border-gray-500 border-dashed" />
          <div className="block -space-y-2">
            <div className="flex">
              <div className="flex items-center justify-center w-10 h-10 text-2xl text-white bg-black border-2 border-white rounded-full">
                {recipient.firstName[0]}
              </div>
              <p className="p-2">{`${recipient.firstName} ${recipient.lastName}`}</p>
            </div>
            <div className="flex">
              <div className="flex items-center justify-center w-10 h-10 text-2xl text-white bg-black border-2 border-white rounded-full">
                {contact.firstName[0]}
              </div>
              <p className="p-2">{`${contact.firstName} ${contact.lastName}`}</p>
            </div>
          </div>
        </div>
        <div className="mt-4 mb-4 border border-t-0 border-gray-200 rounded" />

        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            value=""
            className="sr-only peer"
            onChange={toggleHasMet}
            checked={haveMet}
          />
          <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600" />
          <span className="ml-3 text-sm font-medium text-gray-900">
            Have you both met yet?
          </span>
        </label>
        {!haveMet ? (
          <div>
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Do you still intend/want to meet?
            </label>
            <div className="flex items-center mb-4">
              <input
                id="default-radio-1"
                type="radio"
                value=""
                checked={unsavedFeedback.intentionToMeet === true}
                onClick={() => setIntention(true)}
                name="intend-to-meet"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 "
              />
              <label
                htmlFor="default-radio-1"
                className="ml-2 text-sm font-medium text-gray-900 "
              >
                Yes
              </label>
            </div>
            <div className="flex items-center">
              <input
                checked={unsavedFeedback.intentionToMeet === false}
                id="default-radio-2"
                type="radio"
                value=""
                onClick={() => setIntention(false)}
                name="intend-to-meet"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 "
              />
              <label
                htmlFor="default-radio-2"
                className="ml-2 text-sm font-medium text-gray-900 "
              >
                No
              </label>
            </div>
            {unsavedFeedback.intentionToMeet && (
              <>
                <p className="block mb-2 pt-2 text-sm font-medium text-gray-900">
                  {
                    "Glad you're still interested in meeting! If you already have a date set to meet, feel free to provide it below so I can follow up again shortly thereafter! Otherwise, leave blank and I'll check in again in the next 2 weeks."
                  }
                </p>
                <Datepicker
                  value={''}
                  options={datepickerOptions}
                  onChange={handleDatepickerChange}
                  show={showDatePicker}
                  setShow={handleDatepickerClose}
                />
              </>
            )}
            <label
              htmlFor="message"
              className="block mb-2 py-2 text-sm font-medium text-gray-900"
            >
              Is there anything I can do to help?
            </label>
          </div>
        ) : (
          <div>
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              How was your intro experience?
            </label>
            <div className="items-center my-2">
              <div className="block">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  checked={unsavedFeedback.introExperience === 'POSITIVE'}
                  onClick={() => setIntroExperience('POSITIVE')}
                  name="experience"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-radio-1"
                  className="ml-2 text-sm font-medium"
                >
                  Positive
                </label>
              </div>

              <div className="block">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  checked={unsavedFeedback.introExperience === 'NEUTRAL'}
                  onClick={() => setIntroExperience('NEUTRAL')}
                  name="experience"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-radio-1"
                  className="ml-2 text-sm font-medium"
                >
                  Neutral
                </label>
              </div>

              <div className="block mb-2">
                <input
                  id="default-radio-1"
                  type="radio"
                  value=""
                  checked={unsavedFeedback.introExperience === 'NEGATIVE'}
                  onClick={() => setIntroExperience('NEGATIVE')}
                  name="experience-radio"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-radio-1"
                  className="ml-2 text-sm font-medium"
                >
                  Negative
                </label>
              </div>

              <label htmlFor="message">Was this relationship a good fit?</label>
              <div className="items-center mb-4 py-2">
                <div className="block">
                  <input
                    id="default-radio-1"
                    type="radio"
                    value=""
                    checked={unsavedFeedback.isGoodFit === true}
                    onClick={() => setGoodFit(true)}
                    name="good-fit"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-radio-1"
                    className="ml-2 text-sm font-medium"
                  >
                    Yes
                  </label>
                </div>

                <div className="block">
                  <input
                    id="default-radio-1"
                    type="radio"
                    value=""
                    checked={unsavedFeedback.isGoodFit === false}
                    onClick={() => setGoodFit(false)}
                    name="good-fit"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="default-radio-1"
                    className="ml-2 text-sm font-medium"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <label htmlFor="notes">
              Was there output to the relationship? Please Explain.
            </label>
          </div>
        )}
        <textarea
          id="message"
          rows="4"
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Write your thoughts here..."
          onChange={setNotes}
        />
        <div className="flex w-full">
          <button
            type="button"
            onClick={() => submitFeedback(feedbackResponse.introductionId, contact.id, recipient.id)}
            className="items-center justify-center flex w-full px-2 py-2 mt-4 font-bold text-white bg-blue-500 border border-blue-700 hover:bg-blue-700 rounded-md"
          >
            <ArrowUturnLeftIcon className="w-4 h-4 mr-2" />
            Send Feedback
          </button>
        </div>
      </div>
      <div className="pt-8">
        <Footer />
      </div>
    </div>
  );
}
