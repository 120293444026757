// Import the required modules and assets
import React from 'react';
import SignInWithGoogle from './SignInWithGoogle';
import Logo from '../icon/Logo.png';
import Banner from '../icon/signin.png';
import Icon from '../icon/login/icon-shapes.svg';

/**
 * Sign in Component
 * @returns Sign in Component
 */
export default function SignIn() {
  const list = [
    'Make intros fast without sacrificing detail',
    'Automatically collect feedback on all your intros',
    'Track all your intros in one place, free from any distraction',
  ];

  return (
    <div className="flex flex-col bg-gray-100">
      {/* Navbar */}
      <div className="bg-gray-100 flex md:justify-center pl-6  md:pl-0 items-center h-24">
        <img src={Logo} className="w-28 pt-3" alt="Loop Icon" />
      </div>

      {/* Content */}
      <div className="flex justify-center">
        <div className="grid grid-cols-1 md:grid-cols-2  bg-white shadow-md rounded-lg mx-2 px-4 md:p-0 md:mx-0">
          {/* Image */}
          <img
            src={Banner}
            alt="Happy Person"
            className="rounded-md hidden md:block h-[717px] w-[510px]"
          />

          {/* Information */}
          <div className="flex flex-col justify-center items-center w-fit">
            <div>
              <h2 className="text-xl md:text-4xl font-bold mb-4 mt-6 md:mt-0">
                Create your account
              </h2>
              <p className="text-lg font-semibold mb-6 mr-10">
                Get ready to upgrade the way you make introductions and connect!
              </p>

              {/* List of items */}
              <div className="space-y-4">
                {list.map((item, index) => (
                  <div
                    key={item}
                    className="flex items-start text-lg font-medium space-x-2"
                  >
                    <img
                      src={Icon}
                      width={18}
                      height={18}
                      className="pt-2 md:pt-1"
                      alt="Icon"
                    />
                    <span>{item}</span>
                  </div>
                ))}
              </div>

              {/* Google sign-in button */}
              <div className="mt-6">
                <SignInWithGoogle />
              </div>

              {/* Legal text */}
              <p className="text-md mt-6 mb-6 md:mb-0">
                By creating an account you agree to Looop’s
                <a className="text-blue-500" href="/terms-of-service">
                  {' Terms of Service'}
                </a>{' '}
                &{' '}
                <a className="text-blue-500" href="/privacy-policy">
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="flex justify-center text-md py-4 mt-auto">
      &copy; 2025 Well Aware, Inc. All rights reserved.
      </div>
    </div>
  );
}
